function getRarity(rarity) {
    let tagText = ""
    let tagColor = ""
    const rarities = rarity.map(element => {
        switch (element) {
            case "Super Rare":
                tagText = "SR";
                tagColor = "#E7B12F"
                break;
            case "Rare":
                tagText = "R";
                tagColor = "#2FA2E7"
                break;
            case "Secret Rare":
                tagText = "SEC";
                tagColor = "#8929B1"
                break;
            case "Leader":
                tagText = "L";
                tagColor = "#E7B12F";
                break;
            case "Alternative Art":
                tagText = "AA";
                tagColor = "#E717DF";
                break;
            case "Special Art":
                tagText = "SA"
                tagColor = "#22B3E1";
                break;
            default:
                break;
        }
        return (
            <>
                <text className="raritytags" style={{ color: tagColor, borderColor: tagColor }}>{tagText}</text>
            </>
        )
    });
    return rarities;
}

const Rarity = ({ rarity }) => {
    return (
        <>
            <section className="card-rarity" style={{ padding: "10px" }}>
                <section>{getRarity(rarity)}</section>
            </section>
        </>
    )
}

export default Rarity;
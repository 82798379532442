import { BsCurrencyDollar, BsCollection } from "react-icons/bs";
import Rarity from "./Rarity";

const Card = ({ image, name, price, stock, rarity }) => {
  return (
    <>
      <section className="card">
        <img src={image} alt={name} className="card-img" />
        <div className="card-details">
          <h3 className="card-name">{name}</h3>
          <section className="card-price">
            <div className="price">
              <BsCurrencyDollar /> {price}
            </div>
            <div className="stock">
              <BsCollection /> {stock}
            </div>
          </section>
          <Rarity rarity={rarity}/>
        </div>
      </section>
    </>
  );
};

export default Card;

import { useEffect, useState } from "react";

import Navigation from "./Navigation/Nav";
import Products from "./Products/Products";
// import products from "./db/data";
import fetchData from "./db/fetchCards"
import Recommended from "./Recommended/Recommended";
import Sidebar from "./Sidebar/Sidebar";
import Card from "./components/Card";
import Airtable from 'airtable'
import "./index.css";

var base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_OP5_TOKEN }).base('apppOmE9PEHeL0u7h');

function App() {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    fetchData(setCards)
  }, [])

  // ----------- Input Filter -----------
  const [query, setQuery] = useState("");

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const filteredItems = cards.filter(
    (card) => card.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
  );

  // ----------- Radio Filtering -----------
  const handleChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  // ------------ Button Filtering -----------
  const handleClick = (event) => {
    setSelectedCategory(event.target.value);
  };

  function filteredData(selected, query) {
    let filteredProducts = cards;
    // console.log('filteredProducts', filteredProducts)

    // Filtering Input Items
    if (query) {
      filteredProducts = filteredItems;
    }

    // Applying selected filter
    if (selected) {
      filteredProducts = filteredProducts.filter(
        ({ price, name,rarity }) =>
          // category === selected ||
          // color === selected ||
          // company === selected ||
          rarity.some((r)=>r === selected) ||
          name === selected
      );
    }
    return filteredProducts.map(
      (product) => {
        return (
          <Card
            key={Math.random()}
            image={product.image}
            name={product.name}
            stock={product.stock}
            price={product.price}
            rarity={product.rarity}
          />
        )
      }
    );
  }

  const result = filteredData(selectedCategory, query);

  return (
    <>
      <Sidebar handleChange={handleChange} />
      <Navigation query={query} handleInputChange={handleInputChange} />
      {/* <Recommended handleClick={handleClick} /> */}
      <Products result={result} />
    </>
  );
}

export default App;

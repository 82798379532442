import Airtable from 'airtable'

var base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_OP5_TOKEN }).base('apppOmE9PEHeL0u7h');
const Rarity = {
    Common: "Common",
    Uncommon: "Uncommon",
    Rare: "Rare",
    SuperRare: "Super Rare",
    SuperSecretRare: "Super Secret Rare",
    Leader: "Leader",
    AlternativeArt: "Alternative Art",
    SpecialArt: "Special Art"
}

const fetchData = async (setCards) => {
    base('Cards').select({
        // Selecting the first 3 records in Grid view:
        view: "Inventory"
    }).eachPage((records, fetchNextPage) => {
        // This function (`page`) will get called for each page of records.

        records.forEach((record) => {
            // console.log('record', record)
            const { Name, Price, Rarity, Stock, Image } = record.fields;
            // cards.push(
            const card = { name: Name, price: Price, rarity: Rarity, stock: Stock, image: Image[0].url };
            setCards(prevState => [...prevState, card]);
        });

        // To fetch the next page of records, call `fetchNextPage`.
        // If there are more records, `page` will get called again.
        // If there are no more records, `done` will get called.
        fetchNextPage();
    }, function done(err) {
        if (err) { console.error(err); }
    });
}

export default fetchData

